import requests from '@/requests'
import { CUSTOM_FIELD, CONTACTS_OBJECT } from '@/assets/variables/endpoints'

export const state = () => ({
  settings: [],
  fieldsType: [
    {
      name: 'Single-line Text',
      slug: 'single',
    },
    {
      name: 'Textarea',
      slug: 'textarea',
    },
    {
      name: 'Dropdown',
      slug: 'dropdown',
    },
    {
      name: 'Multiple Select',
      slug: 'multiple',
    },
    {
      name: 'File Upload',
      slug: 'fileupload',
    },
    {
      name: 'Date',
      slug: 'date',
    },
    {
      name: 'Number',
      slug: 'number',
    },
  ],
  validationList: [],
})

export const actions = {
  getCustomField({ commit, state }, params) {
    // commit('layouts/UPDATE_CONTENTLOADING', { status: true }, { root: true })
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })

    requests.whatsapp
      .getService(`${CUSTOM_FIELD}`, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          if (res.data.length) {
            // commit('layouts/UPDATE_CONTENTLOADING', { status: false }, { root: true })
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
            commit('SET_SETTING_DATA', res.data)
            commit('PREPARE_SETTING_DATA')
          }
          // commit('layouts/UPDATE_CONTENTLOADING', { status: false }, { root: true })
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          // commit('layouts/UPDATE_CONTENTLOADING', { status: false }, { root: true })
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        }
      )
  },
  getCustomFieldSettingPage({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(`${CUSTOM_FIELD}`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            if (res.data.length) {
              resolve(res)
            }
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
          }
        )
    })
  },
  addCustomField({ commit, state }, params) {
    commit('layouts/UPDATE_CONTENTLOADING', { status: true }, { root: true })
    const payload = {
      id: params.field.id,
      name: params.field.name,
      type: params.field.type,
      is_active: params.field.is_active,
      is_required: params.field.is_required,
      value: params.field.value,
      validation: params.field.validation,
    }
    if (params.field.type === 'date' || params.field.type === 'number')
      payload.validation = [params.field.type]
    if (!payload.validation.length) delete payload.validation
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .postService(`${CUSTOM_FIELD}`, payload, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { status: false },
              { root: true }
            )
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'success',
                message: 'Successfully Updated!',
                item: '',
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            resolve(res)
          },
          (err) => {
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { status: false },
              { root: true }
            )
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  updateCustomField({ commit, state }, params) {
    commit('layouts/UPDATE_CONTENTLOADING', { status: true }, { root: true })
    const payload = {
      id: params.field.id,
      is_active: params.field.is_active,
      is_required: params.field.is_required,
      value: params.field.value,
      validation: params.field.validation,
    }
    if (!payload.validation.length) delete payload.validation

    return new Promise((resolve, reject) => {
      requests.whatsapp
        .putService(`${CUSTOM_FIELD}/${params.field.id}`, payload, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { status: false },
              { root: true }
            )
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'success',
                message: 'Successfully Updated!',
                item: '',
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            resolve(res)
          },
          (err) => {
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { status: false },
              { root: true }
            )
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  async addValueContactField({ commit, state }, params) {
    commit('layouts/UPDATE_CONTENTLOADING', { status: true }, { root: true })
    const sendToApi = (element) => {
      const formData = new FormData()
      formData.append('contact_handler_id', element.idContactHandler)
      formData.append('contact_custom_field_id', element.idContactCustomField)
      formData.append('name', element.name.replace(/_/g, ' '))
      formData.append('type', element.type)
      if (element.type === 'date') {
        formData.append('date_value', element.value)
        formData.append(
          'validate',
          element.validate.length ? element.validate : 'date'
        )
      } else if (element.type === 'fileupload') {
        formData.append('file', element.value)
        formData.append(
          'validate',
          element.validate.length ? element.validate : 'text'
        )
      } else if (element.type === 'number') {
        formData.append('value[]', element.value)
        formData.append(
          'validate',
          element.validate.length ? element.validate : 'number'
        )
      } else if (element.type === 'multiple') {
        for (let i = 0; i < element.value.length; i++) {
          formData.append('value[]', element.value[i])
        }
        formData.append('validate', 'text')
      } else {
        formData.append('value[]', element.value)
        formData.append(
          'validate',
          element.validate.length ? element.validate : 'text'
        )
      }

      return new Promise((resolve, reject) => {
        requests.whatsapp
          .postService(
            `${CONTACTS_OBJECT}/${element.idContactHandler}/contact_field`,
            formData,
            { Authorization: this.$auth.getToken('hub') }
          )
          .subscribe(
            (res) => {
              resolve(true)
            },
            (err) => {
              commit(
                'layouts/UPDATE_CONTENTLOADING',
                { status: false },
                { root: true }
              )
              const title = `Error in ${element.name.replace(/_/g, ' ')}: ${
                err.error.messages[0]
              }`
              commit(
                'layouts/DISPLAY_TOAST_PIXEL',
                { title, variant: 'error' },
                { root: true }
              )
              reject(err)
            }
          )
      })
    }

    const submitAddValueContactField = async () => {
      commit('layouts/UPDATE_CONTENTLOADING', { status: true }, { root: true })
      for (let i = 0; i < params.length; i++) {
        const el = params[i]
        await sendToApi(el)
      }
      commit('layouts/UPDATE_CONTENTLOADING', { status: false }, { root: true })
      commit(
        'layouts/UPDATE_NOTIFICATION',
        {
          display: true,
          type: 'success',
          message: 'Successfully Added Profile Information!',
          item: '',
          callback: {
            text: 'OK',
            method: 'closeNotification',
          },
        },
        { root: true }
      )
    }
    await submitAddValueContactField()
  },
  async editValueContactField({ commit, state }, params) {
    commit('layouts/UPDATE_CONTENTLOADING', { status: true }, { root: true })
    const sendToApi = (element) => {
      const formData = new FormData()
      formData.append('id', element.idCustomFieldObject)
      formData.append('contact_handler_id', element.idContactHandler)
      formData.append('contact_custom_field_id', element.idContactCustomField)
      formData.append('name', element.name)
      formData.append('type', element.type)
      if (element.type === 'date') {
        formData.append('date_value', element.value)
        formData.append(
          'validate',
          element.validate.length ? element.validate : 'date'
        )
      } else if (element.type === 'fileupload') {
        formData.append('file', element.value)
        formData.append(
          'validate',
          element.validate.length ? element.validate : 'text'
        )
      } else if (element.type === 'number') {
        formData.append('value[]', element.value)
        formData.append(
          'validate',
          element.validate.length ? element.validate : 'number'
        )
      } else if (element.type === 'multiple') {
        for (let i = 0; i < element.value.length; i++) {
          formData.append('value[]', element.value[i])
        }
        formData.append('validate', 'text')
      } else {
        formData.append('value[]', element.value)
        formData.append(
          'validate',
          element.validate.length ? element.validate : 'text'
        )
      }

      return new Promise((resolve, reject) => {
        requests.whatsapp
          .putService(
            `${CONTACTS_OBJECT}/${element.idContactHandler}/contact_field/${element.idCustomFieldObject}`,
            formData,
            {
              Authorization: this.$auth.getToken('hub'),
            }
          )
          .subscribe(
            (res) => {
              commit(
                'layouts/UPDATE_CONTENTLOADING',
                { status: false },
                { root: true }
              )
              resolve(true)
            },
            (err) => {
              commit(
                'layouts/UPDATE_CONTENTLOADING',
                { status: false },
                { root: true }
              )
              if (!err.error.messages[0] === 'file is invalid') {
                commit(
                  'layouts/UPDATE_NOTIFICATION',
                  {
                    display: true,
                    type: 'failed',
                    message: 'Error!',
                    item: err.error.messages[0],
                    callback: {
                      text: 'OK',
                      method: 'closeNotification',
                    },
                  },
                  { root: true }
                )
              }
              reject(err)
            }
          )
      })
    }

    const submitEditValueContactField = async () => {
      commit('layouts/UPDATE_CONTENTLOADING', { status: true }, { root: true })
      for (let i = 0; i < params.length; i++) {
        const el = params[i]
        await sendToApi(el)
      }
      commit('layouts/UPDATE_CONTENTLOADING', { status: false }, { root: true })
      commit(
        'layouts/UPDATE_NOTIFICATION',
        {
          display: true,
          type: 'success',
          message: 'Successfully Updated!',
          item: '',
          callback: {
            text: 'OK',
            method: 'closeNotification',
          },
        },
        { root: true }
      )
    }
    await submitEditValueContactField()
  },
  getListContactObjectValidation({ commit, state }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${CONTACTS_OBJECT}/validation`,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
            resolve(res)
            commit('SET_CONTACT_OBJECT_VALIDATION_LIST', res.data)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
}

export const mutations = {
  ADD_CUSTOMFIELD(state, payload) {
    state.settings.push(payload)
  },
  ADD_UNSHIFT_CUSTOMFIELD(state, payload) {
    state.settings.unshift(payload)
  },
  REMOVE_CUSTOMFIELD(state, payload) {
    state.settings.splice(payload, 1)
  },
  ADD_CUSTOMFIELD_OPTION(state, payload) {
    state.settings[payload].value.push('')
  },
  UPDATE_CUSTOMFIELD_TYPE(state, { e, i }) {
    if (e.slug === 'multiple' || e.slug === 'dropdown') {
      state.settings[i].value = ['Option']
      state.settings[i].validation = []
    } else {
      state.settings[i].validation = []
      state.settings[i].value = []
    }
    state.settings[i].type = e
  },
  UPDATE_CUSTOMFIELD_VALIDATION(state, { e, i }) {
    state.settings[i].validation = [e]
  },
  UPDATE_CUSTOMFIELD_NAME(state, { e, i }) {
    state.settings[i].name = e.target.value
  },
  UPDATE_CUSTOMFIELD_OPTION(state, { e, arr, i }) {
    state.settings[arr].value[i] = e.target.value
  },
  UPDATE_ACTIVE_VALUE(state, { e, i }) {
    state.settings[i].is_active = e
  },
  UPDATE_REQUIRED_VALUE(state, { e, i }) {
    state.settings[i].is_required = e
  },
  REMOVE_CUSTOMFIELD_OPTION(state, { arr, i }) {
    state.settings[arr].value.splice(i, 1)
  },
  SET_SETTING_DATA(state, payload) {
    state.settings = payload
  },
  PREPARE_SETTING_DATA(state) {
    let tmp = ''
    state.settings.forEach((field) => {
      tmp = field.type
      field.type = state.fieldsType.filter(
        (filtered) => tmp === filtered.slug
      )[0]
    })
  },
  VALIDATE_BEFORE_SUBMIT(state) {
    let tmp = ''
    state.settings.forEach((field) => {
      tmp = field.type.slug
      field.type = tmp
      if (field.id === undefined) {
        if (field.type !== 'dropdown' && field.type !== 'multiple') {
          delete field.value
        }
        // maybe used later
        // if (field.validation !== undefined && !field.validation.length > 0) {
        //   delete field.validation
        // }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (field.value !== undefined && !field.value.length > 0) {
          delete field.value
        }
        // maybe used later
        // if (field.validation !== undefined && !field.validation.length > 0) {
        //   delete field.validation
        // }
      }
    })
  },
  SET_CONTACT_OBJECT_VALIDATION_LIST(state, payload) {
    state.validationList = payload
  },
}

export const getters = {}
