import Moment from 'moment'
import _ from 'lodash'
import { getField, updateField } from 'vuex-map-fields'
import requests from '@/requests'
import {
  CONTACT_URL,
  CONTACTS_URL,
  CONTACTS_OBJECT,
  INTEGRATION_URL,
  CONTACTS_HANDLERS,
  BROADCAST_URL,
  CUSTOM_FIELD,
  REPORTS_URL,
  SYSTEM_SETTINGS,
} from '@/assets/variables/endpoints'

export const state = () => ({
  conversationsTable: {
    status: 'loading',
    data: [],
    pagination: {
      offset: 1,
      limit: 6,
      total: 0,
    },
    query: '',
  },
  campaignsTable: {
    status: 'loading',
    data: [],
    pagination: {
      offset: 1,
      limit: 6,
      total: 0,
    },
    query: '',
  },
  agentsHandlingTable: {
    status: 'loading',
    data: [],
    pagination: {
      offset: 1,
      limit: 6,
      total: 0,
    },
    query: '',
  },
  dataTable: [],
  dataTableDummy: [],
  contactObjectFilterParams: {
    start_date: '',
    end_date: '',
    channels: [],
    is_counted: false,
    offset: null,
    query: '',
  },
  contactObjectFilter: {
    channels: [],
    createdAtDateRange: {
      title: 'All Time',
      label: 'all_time',
    },
  },
  contacts: [],
  contactDetail: {
    name: '',
    source_type: '',
    created_at: '',
    contacts: [],
    error_messages: {},
  },
  contactLists: [],
  ids: '',
  filterStatus: null,
  contactDetailData: {
    account_uniq_id: '',
    avatar: '',
    channel: '',
    channel_integration_id: '',
    childs: '',
    code: '',
    contact_handler_id: '',
    created_at: '',
    email: '',
    error_messages: '',
    extra: '',
    full_name: '',
    id: '',
    is_blocked: '',
    is_valid: '',
    last_activity_at: '',
    phone_number: '',
    status: '',
    updated_at: '',
    username: '',
  },
  contactCustomFields: [],
  integrationList: [],
  contactProfileInformation: [],
  showLess: true,
  contactHandleLists: [],
  channelOptions: [
    { value: 'wa', text: 'WhatsApp' },
    { value: 'fb', text: 'Facebook Messenger' },
    { value: 'ig', text: 'Instagram' },
    { value: 'ig_comment', text: 'Instagram Comment' },
    { value: 'email', text: 'Email' },
    { value: 'telegram', text: 'Telegram' },
    { value: 'twitter', text: 'Twitter' },
    { value: 'line', text: 'Line Messenger' },
    { value: 'livechat_dot_com', text: 'Livechat' },
    { value: 'web_chat', text: 'Webchat' },
    { value: 'app_chat', text: 'Mobile chat' },
  ],
  activeRooms: [],
  selectedContactItems: [],
  contact_list_upload_limit: 2001,
  uploadProgress: 0,
  isAllowedToMergeDemerge: false,
})

export const actions = {
  getContactObjectInfinite({ commit, state }, params) {
    return new Promise((resolve) => {
      requests.whatsapp
        .getService(CONTACTS_OBJECT, params.parameter, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit(
              'infinite/SET_META',
              { data: res.meta, source: params.source },
              { root: true }
            )
            commit('SET_CONTACT_HANDLE_LISTS', res.data)
            resolve(res.data)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
          }
        )
    })
  },
  getContactObjectInfiniteByPage({ commit, state }, params) {
    return new Promise((resolve) => {
      requests.whatsapp
        .getService(CONTACTS_OBJECT, params.parameter, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit(
              'infinite/SET_META',
              { data: res.meta, source: params.source },
              { root: true }
            )
            commit('PUSH_CONTACT_HANDLE_LISTS', res.data)
            resolve(res.data)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
          }
        )
    })
  },
  getContactObject({ commit, dispatch, state }, params) {
    const limit = 10
    const isCounted = params.offset === 1
    const payload = {
      limit,
      offset: params.offset,
      channels: state.contactObjectFilterParams.channels
        ? state.contactObjectFilterParams.channels
        : null,
      authoritys: ['own', 'primary'],
      start_date: state.contactObjectFilterParams.start_date
        ? state.contactObjectFilterParams.start_date
        : null,
      end_date: state.contactObjectFilterParams.end_date
        ? state.contactObjectFilterParams.end_date
        : null,
      is_counted: isCounted,
      query: params.query
        ? params.query
        : state.contactObjectFilterParams.query,
      is_contact: false,
    }
    if (params.offset === 1) params.is_counted = true
    commit(
      'layouts/UPDATE_CONTENTLOADING',
      { type: 'table', status: true },
      { root: true }
    )
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(CONTACTS_OBJECT, payload, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          async (res) => {
            if (params.offset === 1)
              commit('layouts/SET_TOTAL_COUNT', res.meta.pagination.total, {
                root: true,
              })
            await commit('layouts/SET_META', res.meta, { root: true })
            // would be used in further
            // await commit('SET_DATATABLE_DUMMY', res.data)
            // const getAssociateAgent = async () => {
            //   const ids = []
            //   for (let i = 0; i < res.data.length; i++) {
            //     const el = res.data[i]
            //     ids.push(el.id)
            //   }
            //   await dispatch('getDataAssociateAgents', ids)
            // }
            // await getAssociateAgent()
            // await commit('SET_DATATABLE', state.dataTableDummy)
            await commit('SET_DATATABLE', res.data)
            await commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'table', status: false },
              { root: true }
            )
            await resolve(res)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'table', status: false },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  async getContactObjectById({ commit, state, dispatch }, params) {
    await commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    await requests.whatsapp
      .getService(
        CONTACTS_OBJECT + '/' + params.id,
        {},
        { Authorization: this.$auth.getToken('hub') }
      )
      .subscribe(
        async (res) => {
          await commit('SET_CONTACT_DETAIL_DATA', res.data)
          await commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
          await dispatch('getContactFields', { contact_id: params.id })
          await dispatch('getActiveRoom', res.data)
        },
        async (err) => {
          await commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          await commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
          await this.$router.push('/contacts')
        }
      )
  },
  getActiveRoom({ commit, state, dispatch }, params) {
    const datas = []
    const parent = {
      id: params.id,
      account_uniq_id: params.account_uniq_id,
    }
    datas.push(parent)

    const childs = params.childs
    for (let i = 0; i < childs.length; i++) {
      const el = childs[i]
      const child = {
        id: el.id,
        account_uniq_id: el.account_uniq_id,
      }
      datas.push(child)
    }
    const activeRoomData = []
    const sendToApi = (el) =>
      new Promise((resolve, reject) => {
        requests.whatsapp
          .getService(
            `${CONTACTS_OBJECT}/${el.id}/active_room`,
            el.account_uniq_id,
            { Authorization: this.$auth.getToken('hub') }
          )
          .subscribe(
            async (res) => {
              const noRoom = {
                id: null,
                status: 'resolved',
                session: 'expired',
              }
              if (res.data.response.length) {
                res.data.response[0].session = ''
                const response = res.data.response[0]
                await activeRoomData.push(response)
              } else {
                await activeRoomData.push(noRoom)
              }
              resolve(res)
            },
            async (err) => {
              await reject(err)
            }
          )
      })

    const getAllRooms = async () => {
      for (let i = 0; i < datas.length; i++) {
        const el = datas[i]
        await sendToApi(el)
      }
      await commit('SET_ACTIVE_ROOM_DATA', activeRoomData)
    }
    getAllRooms()
  },
  async getContactFields({ commit, state }, params) {
    // get custom field contact object detail page
    await commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    await requests.whatsapp
      .getService(`${CONTACTS_OBJECT}/contact_field`, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        async (res) => {
          await commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
          await commit('SET_CONTACT_DETAIL_DATA_CUSTOM_FIELD', res.data)
        },
        async (err) => {
          await commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          await commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
          await this.$router.push('/contacts')
        }
      )
  },
  getCustomFields({ commit, state }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    requests.whatsapp
      .getService(CUSTOM_FIELD, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          commit('UPDATE_CONTACT_PROFILE_INFORMATION', res)
        },
        (err) => {
          commit(
            'layouts/DISPLAY_TOAST',
            {
              message:
                'Something went wrong when getting profile information data',
            },
            { root: true }
          )
          // eslint-disable-next-line no-console
          console.error(err)
        }
      )
    commit('layouts/UPDATE_CONTENTLOADING', { status: false }, { root: true })
  },
  getCustomFieldsNoLoading({ commit, state }, params) {
    requests.whatsapp
      .getService(CUSTOM_FIELD, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          commit('UPDATE_CONTACT_PROFILE_INFORMATION', res)
        },
        (err) => {
          commit(
            'layouts/DISPLAY_TOAST',
            {
              message:
                'Something went wrong when getting profile information data',
            },
            { root: true }
          )
          // eslint-disable-next-line no-console
          console.error(err)
        }
      )
  },
  getContactLists({ commit, state }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    requests.whatsapp
      .getService(
        CONTACT_URL,
        { limit: 10000 },
        { Authorization: this.$auth.getToken('hub') }
      )
      .subscribe(
        (res) => {
          commit('SET_CONTACTS', res.data)
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        }
      )
  },
  getDataContactDetailConversations({ commit, state }, params) {
    commit(
      'layouts/UPDATE_CONTENTLOADING',
      { type: 'table', status: true },
      { root: true }
    )
    if (params.offset === 1) params.is_counted = true
    const payload = {
      limit: state.conversationsTable.pagination.limit,
      offset: params.offset,
      id: state.contactDetailData.id,
      is_counted: params.is_counted,
    }
    requests.whatsapp
      .getService(
        `${CONTACTS_OBJECT}/${state.contactDetailData.id}/conversations`,
        payload,
        { Authorization: this.$auth.getToken('hub') }
      )
      .subscribe(
        (res) => {
          commit('SET_TABLE_CONVERSATIONS', {
            ...res,
            isCounted: params.is_counted,
          })
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { type: 'table', status: false },
            { root: true }
          )
        },
        (err) => {
          commit('SET_TABLE_CONVERSATIONS', {
            ...err,
            isCounted: params.is_counted,
          })
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { type: 'table', status: false },
            { root: true }
          )
        }
      )
  },
  getDataCampaigns({ commit, state }, params) {
    commit(
      'layouts/UPDATE_CONTENTLOADING',
      { type: 'table', status: true },
      { root: true }
    )
    if (params.offset === 1) params.is_counted = true
    const payload = {
      limit: state.campaignsTable.pagination.limit,
      offset: params.offset,
      id: state.contactDetailData.id,
      is_counted: params.is_counted,
    }
    requests.whatsapp
      .getService(
        `${CONTACTS_OBJECT}/${state.contactDetailData.id}/campaign`,
        payload,
        { Authorization: this.$auth.getToken('hub') }
      )
      .subscribe(
        (res) => {
          commit('SET_TABLE_CAMPAIGNS', {
            ...res,
            isCounted: params.is_counted,
          })
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { type: 'table', status: false },
            { root: true }
          )
        },
        (err) => {
          commit('SET_TABLE_CAMPAIGNS', {
            ...err,
            isCounted: params.is_counted,
          })
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { type: 'table', status: false },
            { root: true }
          )
        }
      )
  },
  getDataAgentHandling({ commit, state }, params) {
    commit(
      'layouts/UPDATE_CONTENTLOADING',
      { type: 'table', status: true },
      { root: true }
    )
    if (params.offset === 1) params.is_counted = true
    const payload = {
      limit: state.agentsHandlingTable.pagination.limit,
      offset: params.offset,
      id: state.contactDetailData.id,
      is_counted: params.is_counted,
    }
    requests.whatsapp
      .getService(
        `${CONTACTS_OBJECT}/${state.contactDetailData.id}/agents`,
        payload,
        { Authorization: this.$auth.getToken('hub') }
      )
      .subscribe(
        (res) => {
          commit('SET_TABLE_AGENTS_HANDLING', {
            ...res,
            isCounted: params.is_counted,
          })
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { type: 'table', status: false },
            { root: true }
          )
        },
        (err) => {
          commit('SET_TABLE_AGENTS_HANDLING', {
            ...err,
            isCounted: params.is_counted,
          })
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { type: 'table', status: false },
            { root: true }
          )
        }
      )
  },
  getDataAssociateAgents({ commit, state }, ids) {
    const payload = { contact_ids: ids }
    return new Promise((resolve) => {
      requests.whatsapp
        .getService(`${REPORTS_URL}/contact/list-agents`, payload, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('UPDATE_DATA_TABLE_ADD_AGENTS', res)
            resolve(res)
          },
          (err) => {
            resolve(err)
          }
        )
    })
  },
  addNewContactObject({ state, commit, dispatch }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    const myHeaders = new Headers()
    myHeaders.append('Authorization', this.$auth.getToken('hub'))

    const formdata = new FormData()
    formdata.append('full_name', params.name)
    formdata.append('channel', params.channel.value)
    formdata.append('channel_integration_id', params.channelIntegrationId)
    if (params.channel.value === 'wa' || params.channel.value === 'wa_cloud') {
      formdata.append(
        'account_uniq_id',
        params.phoneCountryCode.code.replace('+', '') + params.accUniqId
      )
    } else {
      formdata.append('account_uniq_id', params.accUniqId)
    }

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow',
    }
    return new Promise((resolve, reject) => {
      fetch(CONTACTS_OBJECT, requestOptions)
        .then((data) => data.json())
        .then((res) => {
          if (res.status === 'success') {
            commit(
              'layouts/DISPLAY_TOAST',
              { message: 'Successfully added single contact' },
              { root: true }
            )
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
            resolve(res.data)
          } else {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: res.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
            reject(res)
          }
        })
    })
  },
  getChannelsIntegrationList({ commit, state, dispatch }, params) {
    const payload = {
      limit: 50,
      offset: 1,
    }
    requests.whatsapp
      .getService(INTEGRATION_URL, payload, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          commit('UPDATE_INTEGRATION_LIST', res.data)
          if (res?.meta?.pagination?.total >= payload.limit) {
            dispatch('getNextChannelIntegrationList', {
              limit: payload.limit,
              offset: payload.offset + 1,
            })
          }
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err?.error?.messages?.[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
        }
      )
  },
  getNextChannelIntegrationList({ commit, dispatch }, params) {
    requests.whatsapp
      .getService(INTEGRATION_URL, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          commit('CONCAT_INTEGRATION_LIST', res.data)
          if (res?.meta?.pagination?.total >= params.limit) {
            dispatch('getNextChannelIntegrationList', {
              limit: params.limit,
              offset: params.offset + 1,
            })
          }
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err?.error?.messages?.[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
        }
      )
  },
  contactHandlers({ state, commit, dispatch }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    const payload = {
      query: params.account_uniq_id,
      reference_contact_id: params.contact_object_id,
      channel_integration_id: params.channel_integration_id,
      channel: params.channel,
    }
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(CONTACTS_HANDLERS, payload, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          async (res) => {
            await resolve(res)
          },
          async (err) => {
            await reject(err)
          }
        )
      commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
    })
  },
  mergeContactObject({ state, commit, dispatch }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .postService(CONTACTS_OBJECT + `/merge`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          async (res) => {
            await commit(
              'layouts/DISPLAY_TOAST',
              { message: 'Contact merged succesfully' },
              { root: true }
            )
            await dispatch('getContactObjectById', {
              id: params.contact_handler_id,
            })
            await resolve(res)
          },
          async (err) => {
            await reject(err)
          }
        )
      commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
    })
  },
  handlersMerge({ state, commit, dispatch }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .postService(CONTACTS_URL + `/handlers/merge`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          async (res) => {
            await commit(
              'layouts/DISPLAY_TOAST',
              { message: 'Contact merged succesfully' },
              { root: true }
            )
            await dispatch('getContactObjectById', {
              id: params.handler_contact_id,
            })
            await resolve(res)
          },
          async (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            await reject(err)
          }
        )
      commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
    })
  },
  async demergeContact({ state, commit, dispatch }, params) {
    await commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .deleteService(CONTACTS_URL + `/handlers/demerge`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          async (res) => {
            if (!params.isMoveHandler) {
              await commit(
                'layouts/DISPLAY_TOAST',
                { message: 'Successfully deleted contact handle' },
                { root: true }
              )
            }
            await dispatch('getContactObjectById', {
              id: params.handler_contact_id,
            })
            await resolve(res)
          },
          async (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            await reject(err)
          }
        )
      commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
    })
  },
  validateWhatsappAccPhoneNumber({ state, commit }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    return new Promise((resolve, reject) => {
      const myHeaders = new Headers()
      myHeaders.append('Authorization', this.$auth.getToken('hub'))

      const formdata = new FormData()
      formdata.append('phone_numbers[]', params.phone_numbers)
      formdata.append('channel_integration_id', params.channel_integration_id)
      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow',
      }
      fetch(BROADCAST_URL + '/contacts', requestOptions)
        .then((data) => data.json())
        .then((res) => {
          // if (res.data.contacts[0].status === 'invalid') {
          // } else {
          //   await commit('layouts/DISPLAY_TOAST', { message: 'Phone number valid' }, { root: true })
          // }
          resolve(res)
        })
        .catch(async (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          await reject(err)
        })
      commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
    })
  },
  uploadMultipleContactsObject({ state, commit }, params) {
    return new Promise((resolve, reject) => {
      commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
      const formdata = new FormData()
      formdata.append('name', params.name)
      formdata.append('file', params.file.data)
      this.$axios.setToken(this.$auth.getToken('hub'))
      this.$axios.setHeader('Access-Control-Allow-Origin', '*')
      this.$axios
        .post(CONTACTS_OBJECT + '/bulk', formdata, {
          onUploadProgress(uploadEvent) {
            const progress = Math.round(
              (uploadEvent.loaded / uploadEvent.total) * 100
            )
            commit('UPDATE_UPLOAD_PROGRESS', progress)
          },
        })
        .then((res) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'success',
              message: 'Your list is uploaded',
              item: '',
              subItem: res.data.id,
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
          commit('UPDATE_UPLOAD_PROGRESS', 0)
          resolve(res)
        })
        .catch((err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message:
                err.response.data.error.code === 429
                  ? 'Unable to upload contact'
                  : 'Error',
              item:
                err.response.data.error.code === 429
                  ? 'You can upload contact every 5 minutes. Please wait for a moment'
                  : err.response.data.error.messages[0] ||
                    'Something went wrong',
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
          commit('UPDATE_UPLOAD_PROGRESS', 0)
          reject(err)
        })
    })
  },
  async addValueContactField({ commit, state, dispatch }, params) {
    await commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    const sendToApi = (element) => {
      commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
      const formData = new FormData()
      formData.append('contact_handler_id', element.contactHandlerId)
      formData.append('contact_custom_field_id', element.contactCustomFieldId)
      formData.append('name', element.name.replace(/_/g, ' '))
      formData.append('type', element.type)
      if (element.type === 'date') {
        formData.append('date_value', element.value)
        formData.append(
          'validate',
          element.validate.length ? element.validate : 'date'
        )
      } else if (element.type === 'fileupload') {
        formData.append('file', element.value)
        formData.append(
          'validate',
          element.validate.length ? element.validate : 'text'
        )
      } else if (element.type === 'number') {
        formData.append('value[]', element.value)
        formData.append(
          'validate',
          element.validate.length ? element.validate : 'number'
        )
      } else if (element.type === 'multiple') {
        for (let i = 0; i < element.value.length; i++) {
          formData.append('value[]', element.value[i])
        }
        formData.append('validate', 'text')
      } else {
        formData.append('value[]', element.value)
        formData.append(
          'validate',
          element.validate.length ? element.validate : 'text'
        )
      }
      if (element.id) {
        // put service for edit
        return new Promise((resolve, reject) => {
          requests.whatsapp
            .putService(
              `${CONTACTS_OBJECT}/${element.contactHandlerId}/contact_field/${element.id}`,
              formData,
              { Authorization: this.$auth.getToken('hub') }
            )
            .subscribe(
              (res) => {
                resolve(true)
              },
              (err) => {
                commit(
                  'layouts/UPDATE_NOTIFICATION',
                  {
                    display: true,
                    type: 'failed',
                    message: 'Error!',
                    item: err.error.messages[0],
                    callback: {
                      text: 'OK',
                      method: 'closeNotification',
                    },
                  },
                  { root: true }
                )
                commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
                reject(err)
              }
            )
        })
      } else {
        // post service for post new field
        return new Promise((resolve, reject) => {
          requests.whatsapp
            .postService(
              `${CONTACTS_OBJECT}/${element.contactHandlerId}/contact_field`,
              formData,
              { Authorization: this.$auth.getToken('hub') }
            )
            .subscribe(
              (res) => {
                // commit('layouts/UPDATE_CONTENTLOADING', { status: false }, { root: true })
                resolve(true)
              },
              (err) => {
                // commit('layouts/UPDATE_CONTENTLOADING', { status: false }, { root: true })
                commit(
                  'layouts/UPDATE_NOTIFICATION',
                  {
                    display: true,
                    type: 'failed',
                    message: 'Error!',
                    item: err.error.messages[0],
                    callback: {
                      text: 'OK',
                      method: 'closeNotification',
                    },
                  },
                  { root: true }
                )
                commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
                reject(err)
              }
            )
        })
      }
    }

    const postAddValueContactField = async () => {
      await commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
      for (let i = 0; i < params.length; i++) {
        const el = params[i]
        await sendToApi(el)
      }
      await commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
      await commit(
        'layouts/UPDATE_NOTIFICATION',
        {
          display: true,
          type: 'success',
          message: 'Successfully Updated!',
          item: '',
          callback: {
            text: 'OK',
            method: 'closeNotification',
          },
        },
        { root: true }
      )
    }
    await postAddValueContactField()
  },
  updateContactObjectFullname({ state, commit, dispatch }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    const handlerId = params.id
    const parameter = {
      full_name: params.full_name,
    }
    if (!params.last_name) delete parameter.last_name

    return new Promise((resolve, reject) => {
      requests.whatsapp
        .putService(`${CONTACTS_OBJECT}/${handlerId}`, parameter, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
            resolve(true)
          },
          (err) => {
            commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
            commit(
              'layouts/DISPLAY_TOAST',
              {
                message:
                  'Something went wrong when updating contact First Name & Last Name',
              },
              { root: true }
            )
            resolve(err)
          }
        )
    })
  },
  downloadContactObject({ state, commit, dispatch }, params) {
    const parameter = params.type === 'all' ? null : { contact_ids: params.ids }
    const email = this.$auth.user.email
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${CONTACTS_URL}/download/contact`,
          { ...parameter, email },
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'success',
                message: res.data,
                item: '',
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            resolve(res)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            resolve(err)
          }
        )
    })
  },
  getContactListUploadLimit({ commit }) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          SYSTEM_SETTINGS,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('UPDATE_CONTACT_LIST_UPLOAD_LIMIT', res.data)
            resolve()
          },
          (err) => {
            commit('UPDATE_CONTACT_LIST_UPLOAD_LIMIT', {
              error: true,
              errData: { ...err },
            })
            reject(err)
          }
        )
    })
  },
}

export const mutations = {
  SET_TABLE_CONVERSATIONS(state, payload) {
    if (payload.status === 'error') {
      state.conversationsTable.status = payload.status
      state.conversationsTable.data = []
    } else {
      state.conversationsTable.status = payload.status
      state.conversationsTable.data = payload.data
      if (payload.isCounted) {
        state.conversationsTable.pagination = payload.meta.pagination
      } else {
        state.conversationsTable.pagination = {
          ...state.conversationsTable.pagination,
          offset: payload.meta.pagination.offset,
          limit: payload.meta.pagination.limit,
        }
      }
    }
  },
  SET_TABLE_CAMPAIGNS(state, payload) {
    if (payload.status === 'error') {
      state.campaignsTable.status = payload.status
      state.campaignsTable.data = []
    } else {
      state.campaignsTable.status = payload.status
      state.campaignsTable.data = payload.data
      if (payload.isCounted) {
        state.campaignsTable.pagination = payload.meta.pagination
      } else {
        state.campaignsTable.pagination = {
          ...state.campaignsTable.pagination,
          offset: payload.meta.pagination.offset,
          limit: payload.meta.pagination.limit,
        }
      }
    }
  },
  SET_TABLE_AGENTS_HANDLING(state, payload) {
    if (payload.status === 'error') {
      state.agentsHandlingTable.status = payload.status
      state.agentsHandlingTable.data = []
    } else {
      state.agentsHandlingTable.status = payload.status
      state.agentsHandlingTable.data = payload.data.response
      if (payload.isCounted) {
        state.agentsHandlingTable.pagination = payload.data.pagination
      } else {
        state.agentsHandlingTable.pagination = {
          ...state.agentsHandlingTable.pagination,
          offset: payload.data.pagination.offset,
          limit: payload.data.pagination.limit,
        }
      }
    }
  },
  SET_DATATABLE(state, payload) {
    state.dataTable = payload
  },
  SET_DATATABLE_DUMMY(state, payload) {
    state.dataTableDummy = payload
  },
  UPDATE_DATA_TABLE_ADD_AGENTS(state, payload) {
    let agents = payload?.data
    agents = _.groupBy(agents, 'handler_id')
    const pushAgents = () => {
      Object.keys(agents).forEach(function (key) {
        for (let i = 0; i < state.dataTableDummy.length; i++) {
          const data = state.dataTableDummy[i]
          if (data.id === key) {
            data.agents = agents[key]
          }
        }
      })
    }
    pushAgents()
  },
  UPDATE_TABLE_STATUS(state, payload) {
    state[payload.type].status = payload.res.status
  },
  SET_CONTACTS(state, payload) {
    state.contacts = payload
  },
  SET_CONTACTINFO(state, payload) {
    state.contactDetail = payload
  },
  SET_CONTACTDETAIL(state, payload) {
    state.contactLists = payload
  },
  SET_ID(state, payload) {
    state.ids = payload
  },
  UPDATE_DETAIL_STATUS(state, payload) {
    state.filterStatus = payload
  },
  SET_CONTACT_DETAIL_DATA(state, payload) {
    state.contactDetailData = payload
    if (payload === null) {
      state.contactDetailData = payload
    }
  },
  SET_CONTACT_DETAIL_DATA_CUSTOM_FIELD(state, payload) {
    if (payload.response.length) {
      state.contactCustomFields = payload.response
      for (let i = 0; i < state.contactCustomFields.length; i++) {
        const el = state.contactCustomFields[i]
        if (el.date_value) {
          el.date_value = Moment(el.date_value).utc().format('DD-MM-YYYY')
        }
      }
    }
  },
  UPDATE_INTEGRATION_LIST(state, payload) {
    state.integrationList = payload
  },
  CONCAT_INTEGRATION_LIST(state, payload) {
    const combine = state.integrationList.concat(payload)
    state.integrationList = combine
  },
  UPDATE_CONTACT_OBJECT_FILTER_PARAMS(state, payload) {
    state.contactObjectFilterParams = { ...payload }
  },
  UPDATE_CONTACT_OBJECT_FILTER(state, payload) {
    state.contactObjectFilter = payload
  },
  UPDATE_CONTACT_PROFILE_INFORMATION(state, payload) {
    const datas = payload.data
    const isActiveCustomFields = _.filter(datas, { is_active: true })
    state.contactProfileInformation = isActiveCustomFields
    for (let i = 0; i < isActiveCustomFields.length; i++) {
      const el = isActiveCustomFields[i]
      if (el.is_required === true) {
        state.showLess = false
      } else {
        state.showLess = true
      }
    }
  },
  UPDATE_CUSTOMFIELD_VALUE(state, { e, i }) {
    state.contactProfileInformation[i].fieldValue = e.target.value
  },
  UPDATE_CUSTOMFIELD_VALUE_MULTIPLESELECT(state, { e, i }) {
    if (state.contactProfileInformation[i].fieldValue) {
      state.contactProfileInformation[i].fieldValue.push(e)
    } else {
      state.contactProfileInformation[i].fieldValue = []
      state.contactProfileInformation[i].fieldValue.push(e)
    }
  },
  REMOVE_CUSTOMFIELD_VALUE_MULTIPLESELECT(state, { e, i }) {
    const datas = state.contactProfileInformation[i].fieldValue
    const findIndex = datas.indexOf(e)
    state.contactProfileInformation[i].fieldValue.splice(findIndex, 1)
  },
  UPDATE_SHOWLESS(state, payload) {
    state.showLess = !state.showLess
  },
  UPDATE_FIRSTNAME_CONTACT_DETAIL_DATA(state, payload) {
    state.contactDetailData.full_name = payload
  },
  UPDATE_LASTNAME_CONTACT_DETAIL_DATA(state, payload) {
    state.contactDetailData.last_name = payload
  },
  SET_CONTACT_HANDLE_LISTS(state, payload) {
    const contacts = []
    payload.forEach((el) => {
      if (el.is_blocked === false) contacts.push(el)
    })
    state.contactHandleLists = contacts
  },
  PUSH_CONTACT_HANDLE_LISTS(state, payload) {
    payload.forEach((el) => {
      if (el.is_blocked === false) state.contactHandleLists.push(el)
    })
  },
  SET_ACTIVE_ROOM_DATA(state, payload) {
    state.activeRooms = payload
    calculateRoomSession(state)
  },
  UPDATE_ACTIVE_ROOM_DATA(state, payload) {
    state.activeRooms = payload
  },
  UPDATE_CONTACT_LIST_UPLOAD_LIMIT(state, payload) {
    if (payload.error) {
      return (state.contact_list_upload_limit = 2000)
    }
    state.contact_list_upload_limit = parseInt(payload)
  },
  UPDATE_UPLOAD_PROGRESS(state, payload) {
    state.uploadProgress = payload
  },
  SET_MERGE_DEMERGE_FLAG(state, payload) {
    state.isAllowedToMergeDemerge = !payload
  },
  updateField,
}

export const getters = {
  getField,
}

const calculateRoomSession = (state) => {
  // calculated based on calculateRoomSession in conversation store
  for (let i = 0; i < state.activeRooms.length; i++) {
    const activeRoom = state.activeRooms[i]
    const lastSession = new Date(activeRoom.session_at)
    const expiredSession = new Date(activeRoom.session_at)
    if (activeRoom.channel === 'livechat_dot_com') {
      expiredSession.setHours(lastSession.getHours() + 1)
    } else {
      expiredSession.setDate(lastSession.getDate() + 1)
    }

    const now = new Date()
    const difference = (expiredSession - now) / 1000 / 60

    // Now only wa and wa cloud and meta product that has session
    // if (activeRoom.channel === 'livechat_dot_com') {
    //   if (difference / 60 > 0.5) activeRoom.session = 'open'
    //   else if (difference / 60 > 0 && difference / 60 <= 0.5)
    //     activeRoom.session = 'expiring'
    // } else if (activeRoom.channel !== 'livechat_dot_com') {
    //   if (difference / 60 > 16) activeRoom.session = 'open'
    //   else if (difference / 60 > 0 && difference / 60 <= 8)
    //     activeRoom.session = 'expiring'
    // }

    if (
      activeRoom.channel === 'wa' ||
      activeRoom.channel === 'wa_cloud' ||
      activeRoom.channel === 'fb' ||
      activeRoom.channel === 'ig'
    ) {
      if (difference / 60 > 16) activeRoom.session = 'open'
      else if (difference / 60 > 0 && difference / 60 <= 8)
        activeRoom.session = 'expiring'
      else if (difference < 0) activeRoom.session = 'expired'
    } else {
      activeRoom.session = null
    }

    // if (activeRoom.session !== 'expired') {
    //   state.activeRoom.roomSession = expiredSession
    // } else {
    //   state.activeRoom.roomSession = lastSession
    // }
  }
}
